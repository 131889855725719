import React, { useEffect } from "react";
import { useState } from "react";
import { showMenu$ } from "../../shared/app-service";
import "./Sidebar.scss";

const tabs = ["calendar", "events", "contact"];

const Icon = ({ icon }) => (
  <span className="icons">
    {icon === "calendar" ? (
      <svg
        width="30px"
        height="30px"
        viewBox="0 0 24 24"
        stroke-width="2.03"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 21H5a2 2 0 01-2-2v-9h18v3m-6-9V2m0 2v2m0-2h-4.5M3 10V6a2 2 0 012-2h2M7 2v4M21 10V6a2 2 0 00-2-2h-.5M14.992 18h3M21 18h-3.008m0 0v-3m0 3v3"
          strokeWidth="2.03"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    ) : (
      ""
    )}

    {icon === "events" ? (
      <svg
        width="30px"
        height="30px"
        strokeWidth="2.03"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
          strokeWidth="2.03"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M2.5 12.5l5.5 2L7 18l1 3M17 20.5l-.5-2.5-2.5-1v-3.5l3-1 4.5.5M19 5.5L18.5 7l-3.5.5v3l2.5-1h2l2 1M2.5 10.5l2.5-2L7.5 8l2-3-1-2"
          strokeWidth="2.03"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    ) : (
      ""
    )}

    {icon === "contact" ? (
      <svg
        width="30px"
        height="30px"
        strokeWidth="2.03"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.118 14.702L14 15.5c-2.782-1.396-4.5-3-5.5-5.5l.77-4.13L7.815 2H4.064c-1.128 0-2.016.932-1.847 2.047.42 2.783 1.66 7.83 5.283 11.453 3.805 3.805 9.286 5.456 12.302 6.113 1.165.253 2.198-.655 2.198-1.848v-3.584l-3.882-1.479z"
          strokeWidth="2.03"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    ) : (
      ""
    )}
  </span>
);

const Tab = ({ children, isActive }) => (
  <div className={isActive ? "active" : ""}>{children}</div>
);

const NavHeader = ({ activeTab, onTabClicked }) => (
  <header className="sidebar_header">
    {tabs.map((tab, idx) => (
      <>
        <button
          key={idx}
          type="button"
          onClick={() => onTabClicked(idx)}
          className={`${activeTab === idx ? "active" : ""}`}
        >
          <Icon icon={tab} />
          <span>{tab}</span>
        </button>
        <div
          className="underline"
          style={{ translate: `${activeTab * 100}% 0` }}
        />
      </>
    ))}
  </header>
);

const SideBar = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isMenuShowing, setMenuShowing] = useState(false);

  const handleTabClicked = (tab) => {
    console.log("tab", tab);
    setActiveTab(tab);
  };

  useEffect(() => {
    showMenu$.subscribe((showMenu) => {
      setMenuShowing(showMenu);
    });
  });

  return (
    <>
      {isMenuShowing && (
        <aside className="sidebar">
          <div>
            <NavHeader activeTab={activeTab} onTabClicked={handleTabClicked} />
            <div className="tabs">
              <Tab isActive={activeTab === 0}>
                <div>
                  <h1>Book Me</h1>
                </div>
              </Tab>
              <Tab isActive={activeTab === 1}>
                <div className="tabs_events-container">
                  <div className="tabs_events-container_img-container">
                    <img
                      src="https://awt-site.s3.us-east-2.amazonaws.com/poetry-class.jpeg"
                      alt=""
                    />
                  </div>
                  <div className="tabs_events-container_event-details">
                    <div className="tabs_events-container_event-details_title">
                      Poetry Class
                    </div>
                    <div className="tabs_events-container_event-details_sub-title">
                      <span>When:</span> Feb 16th, 2023
                    </div>
                    <div className="tabs_events-container_event-details_sub-title">
                      <span>Where:</span> Flat Rock Community
                    </div>
                    <div className="tabs_events-container_event-details_sub-title">
                      <span>Time:</span> 3:00pm
                    </div>
                  </div>
                </div>
                <div className="tabs_events-container">
                  <div className="tabs_events-container_img-container">
                    <img
                      src="https://awt-site.s3.us-east-2.amazonaws.com/paint-class-4.jpeg"
                      alt=""
                    />
                  </div>
                  <div className="tabs_events-container_event-details">
                    <div className="tabs_events-container_event-details_title">
                      Painting Class
                    </div>
                    <div className="tabs_events-container_event-details_sub-title">
                      <span>When:</span> Feb 20th, 2023
                    </div>
                    <div className="tabs_events-container_event-details_sub-title">
                      <span>Where:</span> Flat Rock Community
                    </div>
                    <div className="tabs_events-container_event-details_sub-title">
                      <span>Time:</span> 3:00pm
                    </div>
                  </div>
                </div>
                <div className="tabs_events-container">
                  <div className="tabs_events-container_img-container">
                    <img
                      src="https://awt-site.s3.us-east-2.amazonaws.com/pottery-2-class.jpeg"
                      alt=""
                    />
                  </div>
                  <div className="tabs_events-container_event-details">
                    <div className="tabs_events-container_event-details_title">
                      Pottery Class
                    </div>
                    <div className="tabs_events-container_event-details_sub-title">
                      <span>When:</span> Feb 24th, 2023
                    </div>
                    <div className="tabs_events-container_event-details_sub-title">
                      <span>Where:</span> Flat Rock Community
                    </div>
                    <div className="tabs_events-container_event-details_sub-title">
                      <span>Time:</span> 3:00pm
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab isActive={activeTab === 2}>
                <div>
                  <form>
                    <div className="row">
                      <div className="switch-label">Dark Mode</div>
                      <span className="switch">
                        <input id="switch-round" type="checkbox" />
                        <label htmlFor="switch-round"></label>
                      </span>
                    </div>
                    <div className="row">
                      <div className="switch-label">Accessibility Mode</div>
                      <span className="switch">
                        <input id="switch-round" type="checkbox" />
                        <label htmlFor="switch-round"></label>
                      </span>
                    </div>
                    <div className="row">
                      <div className="switch-label">Quirks Mode</div>
                      <span className="switch">
                        <input id="switch-round" type="checkbox" />
                        <label htmlFor="switch-round"></label>
                      </span>
                    </div>
                  </form>
                </div>
              </Tab>
            </div>
          </div>
        </aside>
      )}
    </>
  );
};

export default SideBar;
