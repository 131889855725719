import { useEffect, useRef, useState } from "react";
import {
  appStore,
  decode,
  hasCookieAndStorage,
  setSessionData,
  handleRoute,
} from "@cmj526/made-for-us";
import { useNavigate } from "react-router-dom";
import { appID, handleScrollAnimation } from "../../shared/app-service";

export const useMainHook = () => {
  const [pageData, setPageData] = useState({});
  const [startAnimation, setAnimation] = useState(false);
  const [vid, setVideo] = useState(0);
  const videoEl = useRef(null);
  // const el = document.getElementById("user-img");
  const el2 = document.getElementById("event");
  const history = useNavigate();

  const attemptPlay = () => {
    if (videoEl.current) {
      videoEl?.current?.classList.add("video_animation");
        videoEl?.current?.play().catch((error) => {
          console.error("Error attempting to play", error);
        });
    }
  };

  const changeVideoClass = () => {
    videoEl && videoEl.current.classList.remove("video_animation");
  };

  useEffect(() => {
    hasCookieAndStorage("main")
      ? setPageData(decode(appStore.get(`${appID}main`)))
      : setSessionData(appID, "main", "/api/main", setPageData);
  }, []);

  useEffect(() => {
    attemptPlay();
  }, [vid]);

  window.addEventListener("scroll", () => {
    // setAnimation(handleScrollAnimation(el));
    setAnimation(handleScrollAnimation(el2));
  });

  const processPayment = (lnk) => {
    handleRoute(lnk, "external", history);
  };



  return {
    pageData,
    videoEl,
    setVideo,
    vid,
    changeVideoClass,
    startAnimation,
    processPayment,
  };
};
