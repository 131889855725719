import React from "react";
import styled, { css, keyframes } from "styled-components";
import Navigation from "../../components/Navigation/Navigation";
import SideBar from "../../components/Sidebar/Sidebar";
import { Jumbotron } from "@cmj526/react-components";
import "./Main.scss";

const StrecthRightOut = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const GrayScaleChange = keyframes`
  0% {
    filter: grayscale(100);
  }
  100% {
    filter: grayscale(0);
  }
`;

const UserImg = styled.div`
  width: 40em;
  height: 50em;
  // opacity: 0;
  ${({ shouldAnimate }) =>
    shouldAnimate &&
    css`
      animation: ${StrecthRightOut} 1s ease-in-out forwards;
    `}
`;

const EventBk = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  filter: grayscale(100);
  ${({ shouldAnimate }) =>
    shouldAnimate &&
    css`
      animation: ${GrayScaleChange} 1s ease-in-out forwards;
    `}
`;

const CardBG = styled.div`
  ${({ bgImg }) =>
    bgImg &&
    css`
      background-image: url("${bgImg}");
    `}
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-transition: 1s cubic-bezier(0.445, 0.05, 0.55, 0.95),
    opacity 5s 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: 1s cubic-bezier(0.445, 0.05, 0.55, 0.95),
    opacity 5s 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  pointer-events: none;
`;

const Main = ({
  pageData,
  videoEl,
  setVideo,
  vid,
  changeVideoClass,
  startAnimation,
  processPayment,
}) => {
  console.log("*&&* Page Data", pageData);
  return (
    <div className="container">
      <SideBar />
      <Navigation />
      <Jumbotron
        titleColor="#eddcd2"
        categoryColor="#ff7db1"
        shopBy={pageData.main?.shopBy}
        categories={pageData.main?.categories}
        videoEl={videoEl}
        videoIdx={vid}
        setVideo={setVideo}
        changeVideoClass={changeVideoClass}
      />
      <div className="container__about">
        {/* <EventBk  shouldAnimate={startAnimation} id="event" className="events-img" src="https://awt-site.s3.us-east-2.amazonaws.com/paint-bk-for-tiffany.jpeg" alt="" /> */}
        <h1 className="container__about_title">About Tiffany</h1>
        <div className="container__about_section">
          <UserImg
            shouldAnimate={startAnimation}
            className="container__about_img-container"
          >
            <img src={pageData.main?.userImg} alt="" />
          </UserImg>
          <h2 id="user-img" className="container__about_p">
            Tiffany is not classified as a particular artist because with God
            there are no limitations, no restrictions and all things are
            possible. She began her artwork in pottery as at the age of 8 years
            old through 4-H winning 1st place at the Oakland County 4-H Fair.
            4-H is to teach the youth by using their Head, Heart, Hands and
            Health through the community to benefit others not just oneself.
            Many years later, Tiffany was inspired by her instructor Jan Berry,
            who took the time to teach the fundamentals of painting, allow
            creativity, and to embrace each piece of art as a masterpiece, just
            as Tiffany is. Tiffany is unique, fearfully, and wonderfully made.
            (Psalm 139:14). She has an “ALL in” lifestyle and personality. Every
            art piece made is exclusive, whether through painting, pottery,
            metals all the glory is His. Tiffany is just the vessel. He makes no
            mistakes; it is really His masterpiece. Ephesians 2:10.
            <p>
              <i className="container__about_p_i">
                {" "}
                For we are his workmanship, created in Christ Jesus unto good
                works, which God hath before ordained that we should walk in
                them.
                <br />- Ephesians 2:10
              </i>
            </p>
          </h2>
        </div>
      </div>
      <div className="container__events">
        <div className="events-overlay"></div>
        <EventBk
          shouldAnimate={startAnimation}
          id="event"
          className="events-img"
          src="https://awt-site.s3.us-east-2.amazonaws.com/paint-bk.jpeg"
          alt=""
        />
        <h1 className="container__events_title">Events</h1>
        {pageData?.main?.events &&
          pageData?.main?.events.map((ev, idx) => (
            <div class="eventCard">
              <div class="thumbnail">
                <img class="left" src={ev.img} />
              </div>
              <div class="right">
                <h1>{ev.title}</h1>
                <div class="author">
                  <h2>{ev.address}</h2>
                </div>
                <div class="separator"></div>
                <p>{ev.main}</p>
                <p>{ev.secondary}</p>
              </div>
              <h5>{ev.day}</h5>
              <h6>{ev.month}</h6>
            </div>
          ))}
      </div>

      {pageData && (
        <div className="container__gallery">
          <div className="events-overlay-2"></div>
          <h1 className="container__gallery_title">Tiffany's Art Gallery</h1>
          <div className="container__gallery_top-grid">
            {pageData?.main?.gallery.top &&
              pageData?.main?.gallery.top.map((gal, idx) => (
                <>
                  <div
                    key={idx}
                    className="cardWrap"
                    onClick={() => processPayment(gal.paymentLink)}
                  >
                    <div key={idx} className="galleryCard">
                      <CardBG bgImg={gal.img} />
                      <div className="cardInfo">
                        <h3 className="cardTitle">{gal.name}</h3>
                        <p>{gal.description}</p>
                        <p className="buy-now">{gal.price}</p>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
          <div className="container__gallery_middle-grid">
            {pageData?.main?.gallery.top &&
              pageData?.main?.gallery.middle.map((gal, idx) => (
                <div
                  key={idx}
                  className="cardWrap"
                  onClick={() => processPayment(gal.paymentLink)}
                >
                  <div key={idx} className="galleryCard">
                    <CardBG bgImg={gal.img} />
                    <div className="cardInfo">
                      <h3 className="cardTitle">{gal.name}</h3>
                      <p>{gal.description}</p>
                      <p className="buy-now">{gal.price}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {/* <div className="container__book">
      <div className="events-overlay-2"></div>
          <EventBk
            shouldAnimate={startAnimation}
            id="event"
            className="events-img"
            src="https://awt-site.s3.us-east-2.amazonaws.com/paint-bk-for-tiffany.jpeg"
            alt=""
          />
        <h1 className="container__book_title">{pageData.main?.bookMe}</h1>
        <div className="container__book_container">
          <div className="container__book_container_rh">
            {pageData.main?.categories &&
              pageData.main?.categories.map((category, idx) => (
                <>
                  <div key={idx} className="cardWrap">
                    <div key={idx} className="card">
                      <CardBG bgImg={category.img} />
                      <div className="cardInfo">
                        <h3 className="cardTitle">{category.name}</h3>
                        <p>Grab your 2022 Training materials available now.</p>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
          <div className="container__book_container_lf"></div>
        </div>
      </div> */}
      <div className="container__footer">
        <div className="container__footer_logo">
          <img src={pageData?.main?.awtLogo} alt="" />
          <div className="container__footer_logo_title">
            A.R.T With TEA &copy; 2023
           
          </div>
          <span>"Admirable" | "Rectitude" | "Talent"</span>
          <span>Powered by CMJ Software</span>
        </div>
      </div>
    </div>
  );
};

export default Main;
