import React, { useEffect } from "react";
import "./Navigation.scss";
import { retrieve } from "../../shared/api";
import { useState } from "react";
import SideBar from "../Sidebar/Sidebar";
import { showMenu$ } from "../../shared/app-service";

const Navigation = () => {
  let isShowingMenu = false;
  const [data, setData] = useState({});

  useEffect(() => {
    retrieve("/api/main").then((res) => {
      setData(res.data);
    });
  }, []);

  const showMenu = () => {
    isShowingMenu = !isShowingMenu;
    showMenu$.next(isShowingMenu);
  };

  return (
    <div className="navigation">
      {/* <div onClick={showMenu}>
        <svg
          width="30px"
          height="30px"
          strokeWidth="2.03"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 5h18M3 12h18M3 19h18"
            strokeWidth="2.03"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </div> */}

      <div className="navigation_title">
        <div className="navigation_title_img-container">
          <img src={data.awtLogo} alt="" />
        </div>
        A.R.T With TEA
      </div>
     
    </div>
  );
};

export default Navigation;
